<template>
  <equipment-layer v-if="isLayer === 0" @on-tab-btn="isLayer = 1" />
  <project-layer v-else @on-tab-btn="isLayer = 0" />
</template>

<script>
import { ref } from '@vue/reactivity'
import EquipmentLayer from '../equipmentLayer/index.vue'
import ProjectLayer from '../projectLayer/index.vue'
export default {
  name: 'MonitoringLayer',
  components: {
    EquipmentLayer,
    ProjectLayer
  },
  setup() {
    const isLayer = ref(1)
    return { isLayer }
  }
}
</script>

<style></style>
