<template>
  <div class="project-layer">
    <!-- 导航提示标题 -->
    <!-- <ml-tip :showHeader="false" content="项目图层" /> -->
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip project-layer-tip" style="margin-bottom: 0">
      <div class="tmap-box" :style="{ height: `${tabberHeight(isFull ? 1 : 90)}px` }">
        <div class="tmap-box-aside" :class="{ 'tmap-box-aside-isClose': isClose }">
          <!-- <div>
            <el-button-group :style="{ display: 'flex', padding: '10px' }">
              <el-button style="flex: 1" type="primary" size="small">项目图层</el-button>
              <el-button style="flex: 1" type="info" size="small" @click="onClickTab">设备图层</el-button>
            </el-button-group>
          </div> -->
          <div style="padding: 10px 10px 0">
            <ml-form :model="searchData">
              <!-- 项目名 -->
              <ml-input
                prop="projectNameLike"
                placeholder="请输入项目名"
                v-model="searchData.projectNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                v-show="false"
                submitText
                submitIcon="el-icon-search"
                :showCancel="false"
                @click-submit="getProjectTreeList"
              />
            </ml-form>
          </div>
          <el-tree
            :style="{ height: `${tabberHeight(319)}px`, 'overflow-x': 'hidden' }"
            ref="folderTreeRef"
            :data="projectTreeList"
            node-key="id"
            accordion
            :default-expanded-keys="defaultCheckedKeys"
            :expand-on-click-node="false"
            @node-click="nodeCLick"
          >
            <template #default="{ node, data }">
              <div
                :class="[{ 'is-status': data.status === 'N' }, `tree-span-${node.level}`]"
                :title="node.label"
              >
                <div class="tree-span-coumt">
                  <div>
                    {{ node.label }}
                    <span
                      v-if="[3].includes(node.level)"
                    >({{ data.children ? data.children.length : 0 }})</span>
                  </div>
                </div>
              </div>
            </template>
          </el-tree>
          <div class="tmap-box-aside-close" @click="isClose = !isClose">
            <div class="tmap-box-aside-close-btn"></div>
          </div>
        </div>
        <div class="tmap-box-main">
          <t-amp
            :center="tMapCenter"
            id="projectLayerId"
            :list="projectList"
            :tampProjectId="tampProjectId"
            @view-error-btn="clickView"
          />

          <!-- 底部统计 -->
          <div class="device-type-bar">
            <div class v-for="(item, index) in downTabBar" :key="'bar-' + index">
              <div class="image-box" :class="`${item.className}`">
                <img class="image" :src="item.icon" />
              </div>
              <div class="title">{{ item.title }} （{{ item.value }}）</div>
            </div>
          </div>
        </div>
      </div>
    </ml-tip>
    <!-- <ml-dialog
			:title="projectInfo.projectName"
			width="800px"
			ref="projectInfoDialogRef"
			:showBtn="false"
			:showClose="true"
		>
			<template #body>
				<div class="projectWindow" style="width: 800px">
					<div class="projectWindow-title">项目基础信息</div>
					<div class="projectWindow-body">
						<div>项目名称: {{ projectInfo.projectName }}</div>
						<div>维护单位: {{ projectInfo.companyName }}</div>
						<div></div>
						<div></div>
					</div>
					<div class="projectWindow-body">
						<div>项目经理: {{ projectInfo.projectManager }}</div>
						<div>联系电话: {{ projectInfo.managerPhone }}</div>
						<div>维护负责人: {{ projectInfo.mainOpsUser }}</div>
						<div>联系电话: {{ projectInfo.leaderPhone }}</div>
					</div>
					<div class="projectWindow-title">设备信息</div>
					<div class="projectWindow-body">
						<div>设备总数: {{ deviceCountVo.deviceCount }}</div>
						<div>受监控设备总数: {{ deviceCountVo.monitorCount }}</div>
						<div>故障设备总数: {{ deviceCountVo.errorCount }}</div>
						<div>已处理故障总数: {{ deviceCountVo.completeCount }}</div>
					</div>
					<div class="projectWindow-body" style="justify-content: center; margin: 20px 0">
						<el-button type="success" icon="el-icon-view" @click="clickView">
							查看所有故障
						</el-button>
					</div>
				</div>
			</template>
    </ml-dialog>-->
  </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, replacePerCent } from '@utils'
import TAmp from '../../components/tamap.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { tampCityLocaltionKey } from '@API'
import axios from 'axios'
export default {
  name: 'ProjectLayer',
  components: {
    TAmp
  },
  setup(props, content) {
    const isFull = inject('isFull')
    const folderTreeRef = ref()
    const tMapCenter = ref([])
    const router = useRouter()
    const { commit, dispatch, getters } = useStore()
    const mapLabelData = computed(() => getters.getmapLabelData)
    const searchData = reactive({
      projectNameLike: ''
    })

    // 底部标签栏
    const downTabBar = reactive([
      {
        icon: require('@/assets/image/map/项目.png'),
        title: '正常',
        className: 'png-img',
        name: 'normalNum',
        value: 0
      },
      {
        icon: require('@/assets/image/map/项目-g.png'),
        title: '过期',
        className: 'png-img',
        name: 'expirationNum',
        value: 0
      },
      {
        icon: require('@/assets/image/map/项目-list.png'),
        title: '故障',
        className: 'png-img',
        name: 'malfunctionNum',
        value: 0
      }
    ])

    // 查询项目图层设备数量统计
    const getDeviceTotalLog = () => {
      dispatch('fetchGetProjectLayerStatistics').then(res => {
        if (res.code == 200) {
          const result = res.data
          downTabBar.map(item => {
            const val = result[item.name]
            item.value = val
            return item
          })
        }
      })
    }

    const tampProjectId = ref('')
    // 树配置
    const projectTreeList = ref([])
    const defaultCheckedKeys = ref([])
    // const defaultParams = ref([])
    // const getFirstId = async (item = {}, parentId = []) => {
    //   const { city, companyId } = mapLabelData.value
    //   if (city && companyId) {
    //     parentId = [companyId]
    //     defaultParams.value = [companyId]
    //   } else {
    //     if (item.children && item.children.length > 0) {
    //       parentId = [...parentId, item.id]
    //       if (!['city'].includes(item.type)) {
    //         defaultParams.value = [...defaultParams.value, item.id]
    //       } else {
    //         defaultParams.value = [...defaultParams.value, item.label]
    //       }

    //       return getFirstId(item.children[0], parentId)
    //     } else {
    //       parentId = [...parentId, item.id]
    //       if (!['city', 'deviceType'].includes(item.type)) {
    //         defaultParams.value = [...defaultParams.value, item.id]
    //       } else {
    //         defaultParams.value = [...defaultParams.value, item.label]
    //       }
    //       // return parentId
    //       // return item.id ? [item.id] : []
    //     }
    //   }

    //   return parentId
    // }

    // 获取树
    const getProjectTreeList = () => {
      // const { city, companyId } = mapLabelData.value
      const params = {}
      if (searchData.projectNameLike) {
        params.projectNameLike = replacePerCent(searchData.projectNameLike)
      }
      // if ((city, companyId)) {
      //   params.city = city
      //   params.companyId = companyId
      // }

      dispatch('fetchProjectTreeList', params).then(async data => {
        if (data && data.code === 200 && data.data.length > 0) {
          // if (data.data[0] && data.data[0].children && data.data[0].children.length > 0) {
          //   const nChildren = data.data[0].children[0]
          //   if (nChildren && nChildren.children && nChildren.children.length > 0) {
          //     defaultCheckedKeys.value = [nChildren.children[0].id]
          //   }
          // }
          // defaultCheckedKeys.value = await getFirstId(data.data[0])
          defaultCheckedKeys.value = [0]
          projectTreeList.value = [
            {
              id: '0',
              label: '全部',
              children: data.data
            }
          ]
        } else {
          projectTreeList.value = []
        }
      })
    }
    watch(
      () => projectTreeList.value,
      async newvalue => {
        if (newvalue && newvalue.length > 0) {
          // projectList.value = await getProjectInfos({ companyId: newvalue[0].id })
          // const params = {}
          // let activeTree = ''
          // const [companyId, city, projectId] = defaultParams.value

          // if (companyId) {
          //   params.companyId = companyId
          //   activeTree = defaultCheckedKeys.value[0]
          // }
          // if (city) {
          //   params.city = city
          //   activeTree = defaultCheckedKeys.value[1]
          // }
          // if (projectId) {
          //   params.projectId = projectId
          //   activeTree = defaultCheckedKeys.value[2]
          // }
          // projectList.value = await getProjectInfos()
          // nextTick(() => {
            // folderTreeRef.value.setCurrentKey(activeTree)
          // })
        } else {
          projectList.value = []
        }
      }
    )
    // 获取项目点
    const getProjectPoints = async () => {
      projectList.value = await getProjectInfos()
    }
    // 获取项目
    const projectList = ref([])
    const getProjectInfos = async (params = {}) => {
      const { city, companyId } = mapLabelData.value
      if ((city, companyId)) {
        params.city = city
        params.companyId = companyId
      }
      const data = await dispatch('fetchGetProjectsByCompany', params)
      if (data && data.code === 200) {
        commit('setmapLabelData', {})
      }
      return data.data || []
    }
    // 获取项目详情
    const getProjectInfo = async projectId => {
      const data = await dispatch('fetchGetProjectInfo', projectId)
      return data.data || []
    }

    // 对话框
    // const projectInfoDialogRef = ref()
    // const projectInfo = ref({})
    const deviceCountVo = reactive({
      completeCount: 0,
      deviceCount: 0,
      errorCount: 0,
      monitorCount: 0
    })
    // 树点击事件
    const nodeCLick = async (data, node) => {
      if (node.level === 1) {
        projectList.value = await getProjectInfos()
      } else if (node.level === 2) {
        projectList.value = await getProjectInfos({ companyId: node.data.id })
      } else if (node.level === 3) {
        const a = `https://restapi.amap.com/v3/geocode/geo?address=${node.data.label}&output=json&key=${tampCityLocaltionKey}`
        const localtion = await axios.get(a)
        const { geocodes } = localtion.data || {}
        tMapCenter.value = geocodes[0].location.split(',')
        projectList.value = await getProjectInfos({
          companyId: node.parent.data.id,
          city: node.data.label
        })
      } else if (node.level === 4) {
        if (node.data.id) {
          projectList.value = await getProjectInfos({
            companyId: node.parent.parent.data.id,
            city: node.parent.data.label
          })
          // projectInfo.value = await getProjectInfo(node.data.id)
          const projectInfo = projectList.value.find(item => item.projectId == node.data.id)
          const { latitude, longitude } = projectInfo
          if (latitude && longitude) {
            tMapCenter.value = [longitude, latitude]
          }

          tampProjectId.value = node.data.id
          setTimeout(() => {
            // 重置
            tampProjectId.value = ''
          }, 100)
          // if (projectInfo.value.deviceCountVo) {
          //   Object.keys(deviceCountVo).map(item => {
          //     deviceCountVo[item] = projectInfo.value.deviceCountVo[item] || 0
          //   })
          // }
          // projectInfoDialogRef.value.showDialog = true
        }
      }
    }

    // const onProjectMap = async item => {
    // 	projectInfo.value = await getProjectInfo(item.projectId)
    // 	if (projectInfo.value.deviceCountVo) {
    // 		Object.keys(deviceCountVo).map(item => {
    // 			deviceCountVo[item] = projectInfo.value.deviceCountVo[item] || 0
    // 		})
    // 	}
    // 	projectInfoDialogRef.value.showDialog = true
    // }

    const clickView = item => {
      // console.log(item)
      const { companyId, projectId } = item
      if (companyId && projectId) {
        router.push({
          name: 'FaultServiceCenter',
          params: {
            companyId,
            projectId
          }
        })
      }
    }

    const isClose = ref(false)
    // tab 按钮
    const onClickTab = () => {
      content.emit('on-tab-btn', 0)
    }
    onMounted(async () => {
      getDeviceTotalLog()
      getProjectPoints()
      await getProjectTreeList()
    })
    return {
      tabberHeight,
      searchData,
      projectTreeList,
      defaultCheckedKeys,
      getProjectTreeList,
      nodeCLick,
      projectList,
      // projectInfoDialogRef,
      // projectInfo,
      deviceCountVo,
      // onProjectMap,
      clickView,
      tMapCenter,
      isClose,
      onClickTab,
      folderTreeRef,
      tampProjectId,
      isFull,
      downTabBar
    }
  }
}
</script>

<style lang="scss">
.project-layer {
  @extend %params-global;
  .el-tree {
    background: transparent;
  }
  &-tip {
    .el-card__body {
      padding: 0 !important;
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
}

.is-status {
  // color: #727272;
}
.tmap-box-main {
  position: relative;

  .device-type-bar {
    position: absolute;
    min-width: 100px;
    height: 50px;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    > div {
      margin: 0 5px;

      .image-box {
        width: 30px;
        height: auto;

        &.png-img {
          width: 30px;
          height: 30px;
          margin: 10px;
        }

        .image {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        text-align: center;
      }
    }
  }
}
.tmap-box-aside .el-tree-node:focus > .el-tree-node__content,
.tmap-box-aside .el-tree-node__content:hover,
.tmap-box-aside .el-upload-list__item:hover {
  .is-status {
    color: #fff;
  }
}
.projectWindow {
  width: 1200px;
  padding: 0 10px;
  &-title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
  }
  &-body {
    display: flex;
    > div {
      margin: 10px;
      flex: 1;
    }
  }
}
</style>
